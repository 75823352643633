import { Component, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'agdir-mapbox-detect-fields-control',
	template: `
		<button
			type="button"
			(click)="onDetectFieldsClick()"
			class="flex flex-col items-center justify-center w-full h-auto py-1 px-0.5"
			*ngIf="visible"
		>
			<agdir-spinner *ngIf="isLoading | async; else icon"></agdir-spinner>
			<span>Detect fields</span>
		</button>
		<ng-template #icon>
			<agdir-icon class="w-3" icon="settings_overscan" />
		</ng-template>
	`,
})
export class MapboxDetectFieldsControlComponent {
	detectFieldsClick: EventEmitter<void> = new EventEmitter<void>();
	visible = true;
	isLoading = new BehaviorSubject(false);

	onDetectFieldsClick() {
		this.detectFieldsClick.emit();
	}
}
