import { MultiPolygon } from '@turf/turf';
import { FitBoundsOptions, LngLatBounds } from 'mapbox-gl';

export interface LocationMapSettings {
	zoom: number;
	minZoom: number;
	maxZoom: number;
	bounds: LngLatBounds;
	fitBoundsOptions: FitBoundsOptions;
}

export interface LocationSplitResult {
	source: string;
	locationPolygons: MultiPolygon[];
}

export interface LocationMergeResult {
	source: string[];
	polygon: MultiPolygon;
}

export const DEFAULT_MAP_CONFIG = {
	zoom: 16,
	minZoom: 0,
	maxZoom: 22,
};

export const DEFAULT_FITBOUNDS_OPTIONS: FitBoundsOptions = {
	maxZoom: DEFAULT_MAP_CONFIG.zoom,
	padding: 50,
	duration: 800,
};

// TODO: make configurable per product country in the future?
export const DEFAULT_FITBOUND_LOCATION: { lng: number; lat: number } = {
	lng: 8.7725,
	lat: 58.4615,
};

export const calcBounds = (multiPolygons: MultiPolygon[]): LngLatBounds => {
	const allCoordinates = multiPolygons?.flatMap((multiPolygon) => multiPolygon.coordinates.flat(2)) || [];

	if (allCoordinates.length === 0) {
		return new LngLatBounds();
	}

	const longitudes = allCoordinates.map((coord) => coord[0]);
	const latitudes = allCoordinates.map((coord) => coord[1]);

	const minLng = Math.min(...longitudes);
	const maxLng = Math.max(...longitudes);
	const minLat = Math.min(...latitudes);
	const maxLat = Math.max(...latitudes);

	return new LngLatBounds([minLng, minLat], [maxLng, maxLat]);
};

export const makeMapConfig = (geoPoints: MultiPolygon[], settings: Partial<LocationMapSettings> = {}): LocationMapSettings => {
	return {
		...DEFAULT_MAP_CONFIG,
		fitBoundsOptions: DEFAULT_FITBOUNDS_OPTIONS,
		bounds: calcBounds(geoPoints),
		...settings,
	};
};
