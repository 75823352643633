import { BaseDirective } from '@agdir/core/angular';
import { AfterContentInit, Directive, Host, output, ViewContainerRef } from '@angular/core';
import { ControlComponent, MapService } from 'ngx-mapbox-gl';
import { filter, takeUntil } from 'rxjs';
import { MapboxCustomControlWrapper } from '../../map/controls/mapbox-custom-control-wrapper';
import { ZoningLayerControlComponent } from './zoning-layer-control.component';
import { AgdirFeature, FeatureToggleService } from '@agdir/features';
import { switchMap } from 'rxjs/operators';

@Directive({
	standalone: true,
	selector: '[agdirDigiZoning]',
})
export class ZoningLayerControlDirective extends BaseDirective implements AfterContentInit {
	showZones = output<boolean>();

	constructor(
		private viewContainerRef: ViewContainerRef,
		private mapService: MapService,
		private featureService: FeatureToggleService,
		@Host() private controlComponent: ControlComponent<MapboxCustomControlWrapper>,
	) {
		super();
	}

	ngAfterContentInit(): void {
		this.mapService.mapCreated$
			.pipe(
				switchMap(() => this.featureService.getFeature(AgdirFeature.digimapZoningLayers)),
				filter((disabled) => !disabled),
			)
			.subscribe(() => {
				const componentRef = this.viewContainerRef.createComponent(ZoningLayerControlComponent);
				componentRef.instance.clicked.pipe(takeUntil(this.destroyed$)).subscribe((e) => this.showZones.emit(e));
				this.controlComponent.control = new MapboxCustomControlWrapper(componentRef.location.nativeElement);
				this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
			});
	}
}
